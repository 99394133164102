<template>
  <ClientOnly>
    <div id="cookies" class="cookies mx-auto -mt-12 mb-12 max-w-screen-md p-5 pt-0 text-gray-900 md:-mt-24 md:mb-24">
      <hr class="mx-auto my-4 h-1 w-48 rounded border-0 bg-gray-100 md:my-10" />
      <h1 class="mt-12 text-3xl md:mt-24 md:text-5xl">Einsatz von Cookies</h1>
      <div class="cookies__content mt-6 md:mt-12">
        <Script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/e7535454-f4f9-40f4-a3fe-dd952a48d75b/cd.js"
          type="text/javascript"
          async="async" />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts"></script>

<style scoped>
.cookies__content :deep(a) {
  @apply underline md:no-underline md:hover:underline;
}
</style>
